<template>
  <div v-if="authUserIsAdmin">
    <LoadingMessage v-if="isLoading" :message="'Fetching requests...'"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error" class="my-3"></ErrorMessage>

    <form
      @submit.prevent
      v-if="!isLoading && !error"
      class="d-flex align-items-center bg-dark p-2 rounded"
    >
      <div class="form-floating-cta-container">
        <div class="h5 m-0 px-2 text-white">Requests</div>
      </div>
      <IconButton
        @click="fetchRequests"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading && !error" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Name</th>
              <th scope="col" class="align-baseline">From</th>
              <th scope="col" class="align-baseline">To</th>
              <th scope="col" class="align-baseline">Status</th>
              <th scope="col" class="align-baseline">Requested at</th>
              <th scope="col" class="align-baseline">Actioned at</th>
              <th scope="col" class="align-baseline"></th>
            </tr>
          </thead>
          <tbody class="small">
            <tr v-if="!requests.length">
              <td colspan="7">No requests found</td>
            </tr>
            <RequestListItem
              @update="updateRequest"
              v-for="(request, requestIndex) in requests"
              :key="`key-${requestIndex}`"
              :request="request"
              :index="requestIndex + 1"
            ></RequestListItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '../../../core/http';

export default {
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    RequestListItem: () => import('./RequestListItem.vue'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
  },
  methods: {
    async fetchRequests() {
      this.isLoading = true;
      this.error = null;
      this.keyword = '';
      try {
        const { data } = await httpGet('/subscription/manage/request');
        this.requests = data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    updateRequest(request) {
      const index = this.requests.findIndex((r) => {
        const { coachingPlanRequestId } = r;
        return coachingPlanRequestId === request.coachingPlanRequestId;
      });
      if (index > -1) this.requests.splice(index, 1, request);
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      requests: [],
      keyword: '',
    };
  },
  mounted() {
    this.fetchRequests();
  },
};
</script>

<style lang="scss" scoped>
// th[scope="col"] {
//   &:nth-child(1) {
//     width: 50%;
//   }
//   &:nth-child(2) {
//     width: 35%;
//   }
//   &:nth-child(3) {
//     width: 10%;
//   }
//   &:nth-child(4) {
//     width: 5%;
//   }
// }
</style>
